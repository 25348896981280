<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-title class="title">
                        <v-icon>layers</v-icon>
                        Sales
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card flat>
                        <v-card-title class="title">
                            <v-flex xs4>
                                <v-text-field
                                        hint="Atleast 3 characters"
                                        flat
                                        color="primary"
                                        class="pa-0"
                                        label="Search By Name"
                                        v-model="searchAH"
                                        @input="searchItem"
                                />
                            </v-flex>

                        </v-card-title>
                    </v-card>

                    <v-data-table :headers="headers"
                                  :items="form.items.data"
                                  :expand="expand"
                                  item-key="sale_bill_no"
                                  :total-items="form.items.meta.total"
                                  :search="search" :pagination.sync="pagination" :loading="form.loading"
                                  :rows-per-page-items="rowsPerPageItems">
                        <template slot="items" slot-scope="props">
                            <tr :class="'highlight'" @click="props.expanded = !props.expanded">
                                <td class="text-xs-left">
                                    {{props.index +1}}
                                </td>
                                <td class="text-xs-left">{{ props.item.ledger_head ? props.item.ledger_head.name :'' }}</td>
                                <td class="text-xs-left">{{ props.item.sale_bill_date }}</td>
                                <td class="text-xs-left">{{ props.item.sale_bill_no }}</td>
                                <td class="text-xs-left">{{ props.item.bill_type }}</td>
                                <td class="text-xs-left">{{ props.item.voucher_type ? props.item.voucher_type.name : '' }}</td>
                                <td class="text-xs-right"><v-icon color="primary" @click="print">print</v-icon></td>

                            </tr>
                        </template>
                        <template v-slot:expand="props">
                            <table width="95%" class="in-table">
                                <thead>
                                <tr>
                                    <th class="text-xs-left"><strong>#</strong></th>
                                    <th class="text-xs-left"><strong>Item</strong></th>
                                    <th class="text-xs-left"><strong>WareHouse</strong></th>
                                    <th class="text-xs-left"><strong>Quantity</strong></th>
                                    <th class="text-xs-left"><strong>Rate </strong></th>
                                    <th class="text-xs-left"><strong>Amount</strong></th>
                                </tr>
                                <tr v-for="(x,i)  in props.item.items" :key="i" style="height: 0px" class="footerRow">
                                    <td class="text-xs-left">{{i+1}}</td>
                                    <td class="text-xs-left">{{x.item_name}}</td>
                                    <td class="text-xs-left">{{x.warehouse_name}}</td>
                                    <td class="text-xs-left">{{x.qty}}</td>
                                    <td class="text-xs-left">{{x.rate}}</td>
                                    <td class="text-xs-left">{{x.amount}}</td>
                                </tr>
                                </thead>
                                <tbody>


                                <tr class="`footerRow">
                                    <td :colspan="5" class="text-xs-right">Sub Total:</td>
                                    <td :colspan="2">{{props.item.sub_total.numberFormat()}}</td>
                                </tr>
                                <tr class="footerRow">
                                    <td :colspan="5" class="text-xs-right">Dis %</td>
                                    <td :colspan="2">{{props.item.discount_in_percentage}}</td>
                                </tr>
                                <tr class="footerRow">
                                    <td :colspan="5" class="text-xs-right" width="20px">Dis Amount:</td>
                                    <td :colspan="2">{{props.item.discount_in_amount.numberFormat()}}</td>
                                </tr>
                                <tr class="footerRow">
                                    <td :colspan="5" class="text-xs-right">Taxable Amount:</td>
                                    <td :colspan="2">{{props.item.taxable_amount_after_discount.numberFormat()}}</td>
                                </tr>
                                <tr class="footerRow">
                                    <td :colspan="5" class="text-xs-right">Non Taxable Amount:</td>
                                    <td :colspan="2">{{props.item.non_taxable_amount_after_discount.numberFormat()}}</td>
                                </tr>
                                <tr class="footerRow">
                                    <td :colspan="5" class="text-xs-right">VAT 13%:</td>
                                    <td :colspan="2">{{props.item.vat_amount.numberFormat()}}</td>
                                </tr>
                                <tr class="footerRow">
                                    <td :colspan="5" class="text-xs-right">Total:</td>
                                    <td :colspan="2">{{props.item.total_amount.numberFormat()}}
                                    </td>
                                </tr>
                                <tr class="footerRow" >
                                    <td class="narration" >Narration:</td>
                                    <td class="narration" :colspan="8" width="100%">{{props.item.narration}}</td>
                                </tr>
                                </tbody>

                            </table>

                        </template>

                    </v-data-table>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        data: () => ({
            form: new Form({
                name: '',
                print_name: '',
                item_code: '',
                category_id: '',
                is_service_type: 0,
                purchase_rate: '',
                sales_rate: '',
                mrp_sales: '',
                min_sales_rate: '',
                is_taxable: 0,
                item_data: {
                    warehouse_id: '',
                    qty: '',
                    rate: '',
                    unit: '',
                }
            }, '/api/account/inventory/sale'),
            searchAH: '',
            expand: false,
            searchC: null,
            search: null,
            searchW: null,
            isLoading: false,
            rowsPerPageItems: [5, 10, 25, 50, 75, 100],
            pagination: {
                rowsPerPage: 10,
                sortBy: 'id'
            },
            // categorySearchData: [],
            // wareHouseSearchData: [],
            categories: [],
            units: [],
            wareHouses: [],
            accountHeads: [],
            headers: [
                {text: '#', align: 'left', sortable: false, value: 'id', width: '20px'},
                {text: 'Account Ledger', value: 'name', align: "left",},
                {text: 'Sales Bill Date', value: 'sale_bill_date', align: "left", sortable: false},
                {text: 'Sales Bill No', value: 'sales_bill_no', align: "left", sortable: false},
                {text: 'Bill No', value: 'bill_no', align: "left", sortable: false},
                {text: 'Voucher Type', value: 'voucher_type', align: "left", sortable: false},
                {text: 'Action', value: 'action', align: "right", sortable: false},
            ]
        }),

        computed: {
            ...mapState(['batch']),
        },
        mounted() {
            // this.getUnits();

        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&search=' + this.searchAH;
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },
            searchItem: function (v) {
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.get();
                }, 500);
            },
            print() {
                console.log('printing....');
            }

        },

    }
</script>
<style lang="scss">
    tr.highlight {
        cursor: pointer;
    }

    .footerRow {
        height: 25px !important;
        border: none !important;
    }

    .in-table {
        /*width: 90%;*/

        margin: auto;
        border-collapse: collapse;
        background: rgba(184, 184, 184, 0.37);
        thead {
            /*height: 15px;*/
            border-top: 1px solid #666;
            border-bottom: 1px solid #666;
        }
        tbody td{
            /*background: red;*/
            height: 20px!important;
        }
        .narration {
            border-top: 1px solid #666;
            border-bottom: 1px solid #666;
        }
    }


</style>

